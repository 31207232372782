@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'avenir';
  src: url('./Assets/Fonts/avenir_roman_12.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "avenir", sans-serif;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.custom-button-border {
  @apply border  border-gray-500;
}

@font-face {
  font-family: 'NicoMoji';
  src: url('./Assets/Fonts/NicoMoji-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;

}
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
.roundestyle{
  border-radius: 0px 6px 6px 0px;
}

/* #googleAuth{
  transform: scale(1,2);
} */

.logoText {
  font-family: 'NicoMoji', sans-serif;
  font-weight: 400;
  width: 132px;
  font-size: 40px;
  line-height: 36px;
  margin-bottom: 20px;
}
.logoTextAferAuth {
  font-family: 'NicoMoji', sans-serif;
  font-weight: 400;
  width: 132px;
  font-size: 40px;
  line-height: 36px;
  margin-bottom: 20px;
}


@media (max-width: 768px) {
  .logoTextAferAuth {
    /* font-size: 34px; */
    line-height: 6px;
    width: auto; /* Adjust width as needed */
    margin-bottom: 10px; /* Adjust margin as needed */
  }
}

.loader {
  border-color: #f3f3f3;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* In your global CSS file */
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.scroll-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.scroll-hidden {
  scrollbar-width: none; /* Hide scrollbar */
}

/* Hide scrollbar for Edge and IE */
.scroll-hidden {
  -ms-overflow-style: none; /* Hide scrollbar */
}
